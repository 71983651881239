// src/components/Popup.js
import React, { useEffect } from 'react';
import Button from './Button'; // Import Button component

const Popup = ({ layerName, setLayerName, addLayer, closePopup }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closePopup();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [closePopup]);

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      addLayer();
    }
  };

  return (
    <div>
      <label>
        Layer name:
        <input 
          value={layerName} 
          onChange={e => setLayerName(e.target.value)} 
          onKeyDown={handleInputKeyDown} // Add keydown event listener
        />
      </label>
      <Button onClick={addLayer}>Add Layer</Button> {/* Use Button component */}
    </div>
  );
};

export default Popup;
