// src/components/Greeting.js
import React from 'react';
import { useSelector } from 'react-redux';

const Greeting = ({ name }) => {
  // Select the 'count' field from the state
  const count = useSelector(state => state.count);
  return <h3>Hello, {name}! Count: {count}</h3>;
};

export default Greeting;
