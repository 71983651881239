// src/components/Button.js
import React from 'react';

const Button = ({ onClick, children, ...props }) => {
  return (
    <button onClick={onClick} {...props} className="my-2 mx-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded">
      {children}
    </button>
  );
};

export default Button;
