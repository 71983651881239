import React, { useEffect, useRef } from 'react';

import { useDispatch, useStore } from 'react-redux';
import { setFps, addElements } from '../reducers';
import engine from '../js/McEngine';
// import { append } from 'cheerio/lib/api/manipulation';

var ui = {
    position: null,
    rulers: null,
    pickDistance: 10,
    adjustedPickDistance: 10,
    documentLayer: null,
    backgroundLayer: null,
}

window.ui = ui;


/**
 * @param {string} d
 */
function parsePath(d) {
    let commands = d.match(/[a-z][^a-z]*/ig);
    if(commands === null)
    {
        return [];
    }
    
    let results = [];

    var thisPath = [];

    for (let command of commands) {
        let type = command[0];
        
        // If the command type is 'z' or 'Z', there are no associated points

        if(type.toLowerCase() === 'm' && thisPath.length > 0)
        {
            results.push(thisPath);
            thisPath = [];
        }

        if (type.toLowerCase() === 'z') {
            thisPath.push({t: type, p: []});
            // results.push(thisPath);
            // thisPath = [];

        } else {
            let points = command.slice(1).trim().split(/[ ,]+/).map(Number);
            thisPath.push({t: type, p: points});
        }
    }
    if(thisPath.length > 0)
    {
        results.push(thisPath);
    }

    return results;
}

/**
 * @param {{ (p: any): void; (arg0: any[]): void; }} fn
 */
async function init(fn)
{
// Load the SVG
    fetch('aztec.svg')
    .then(response => response.text())
    .then(data => {
        // Parse the SVG
        let parser = new DOMParser();
        let doc = parser.parseFromString(data, 'image/svg+xml');

        // Extract the paths
        let paths = doc.querySelectorAll('path');

        // Loop through the paths
        var allPaths = [];
        paths.forEach((path, index) => {
            let d = path.getAttribute('d');
            if(d != null)
            {
                let commands = parsePath(d);
                for(var i = 0; i < commands.length; i++)
                {
                    allPaths.push(commands[i]);
                }
            }

        });

        fn(allPaths)
    })
    .catch(error => console.error(error));

}


function Engine() {

    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const dispatch = useDispatch();

    const store = useStore();
    let lastState = store.getState();

    useEffect(() => {
        // Code to run after component is added to the DOM

        var container = document.getElementById('canvasContainer');
        var e = engine.createEngine(container);
        e.setElements(lastState.elements);

        store.subscribe(() => {
            // console.log('store changed', store.getState());
            let currentState = store.getState();
          
            // check if the specific element has changed
            if (lastState.elements !== currentState.elements) {
            //   console.log('elements changed from', lastState.elements, 'to', currentState.elements);
                e.setElements(currentState.elements);
            }
          
            // update lastState to the current state for the next comparison
            lastState = currentState;
        });


      init((p)=>{
        dispatch(addElements(p));
      })

    }, []);
  
    return (
        <div  ref={containerRef} className="rightPane" id="canvasContainer"> </div>
    );
  }


export default Engine;
