// src/components/AddLayerButton.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addLayer } from '../reducers';
import Popup from './Popup';
import Button from './Button';

const AddLayerButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [layerName, setLayerName] = useState('');
  const dispatch = useDispatch();

  const handleAddLayer = () => {
    dispatch(addLayer(layerName));
    setLayerName('');
    setShowPopup(false);
  };

  const closePopup = () => {
    setLayerName('');
    setShowPopup(false);
  };

  return (
    <div>
      {!showPopup && <Button onClick={() => setShowPopup(true)}>Add Layer</Button>}
      {showPopup && <Popup layerName={layerName} setLayerName={setLayerName} addLayer={handleAddLayer} closePopup={closePopup} />}
    </div>
  );
};

export default AddLayerButton;
