

class path
{
    constructor()
    {
        this.uuid = null;
        this.type = "path";
        this.name = "Path";
        this.path = null;
        this.bounds = null;
    }

}

class layer
{
    constructor()
    {
        this.uuid = null;
        this.name = "Layer";
        this.items = [];
        this.color = [];
    }

}


function createState()
{
    var state = {};

    state = {
        count: 0,
        layers: ['Layer1', 'Layer2', 'Layer3'],
        activeLayer: null,
        selectedItems: [],
        elements: [],
        fps: 0,

        documentSettings: {
            width: 100,
            height: 100,
            gridSpacing: 10,
            drawGrid: true,
            displayMm: true,
            snapToGrid: true,
        },

        snaps:
        {
            enabled: true,
            snapToGrid: true,
            snapToItems: true,
        },

        engine: {
            snaps: [],
            draw: [],
            selectable: [],
            selected: [],
        }
    };

    return state;
}

module.exports = createState;