// const { map } = require("cheerio/lib/api/traversing");

var engine = null;

const redrawTime = 100;

const isWindows = navigator.platform.indexOf('Win') > -1;
const hidpi = isWindows?false:true;


/**
 * @param {() => void} func
 * @param {string | null} msg
 */
function measureExecutionTime(func, msg) {
    const start = performance.now();

    // Call the function
    func();

    const end = performance.now();
    const duration = end - start;

    if(msg == null)
    {
        msg = "Execution time";
    }

    console.log(msg, duration.toFixed(2) + 'ms');
}

/**
 * @param {string | number | any[]} r
 * @param {string | number | any[]} g
 * @param {string | number | any[]} b
 */
function rgbToHex(r, g, b) {

    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    if(r.length == 1)
    {
        r = "0" + r;
    }
    if(g.length == 1)
    {
        g = "0" + g;
    }
    if(b.length == 1)
    {
        b = "0" + b;
    }

    return "#" + r + g + b;
}

class McEngine
{
    /**
     * @param {{ prepend: (arg0: HTMLDivElement) => void; }} el
     */
    constructor(el)
    {
        this.zoom = 1;
        this.pan = {x:0, y:0};
        this.layers = [];

        this.isDragging = false;
        this.dragStart = {x:0, y:0};

        this.el = el;
        this.container = null;
        this.pixelRatio = 1;

        this.elements = [];
        this.colors = [];

        this.hoveredElement = -1;

        this.redrawTimeout = null;

        var container = document.createElement('div');
        container.style.width = "100%";
        container.style.height = "100%";
        container.style.margin = "0px";
        container.style.padding = "0px";
        container.style.background = "white";
        // container.style.position = "absolute";
        // container.style.top = "0px";
        // container.style.left = "0px";


        //remove all children of el
        while (this.el.firstChild) {
            this.el.removeChild(this.el.firstChild);
        }

        el.prepend(container);
        this.container = container;


        this.allCanvas = [];

        // this.el.style.position = "relative";
        this.el.padding = "0px";

        var canvas = document.createElement('canvas');
        // canvas.id = "myCanvas";
        canvas.style.zIndex = 8;
        // canvas.style.background = "black";
        canvas.style.width = "100%";
        canvas.style.height = "100%";
        canvas.style.margin = "0px";
        canvas.style.padding = "0px";
        canvas.style.position = "absolute";
        canvas.style.top = "0px";
        canvas.style.left = "0px";

        
        container.prepend(canvas);

        this.allCanvas.push(canvas);

        //create a matching offscreen canvas
        var offscreenCanvas = document.createElement('canvas');
        offscreenCanvas.width = canvas.width;
        offscreenCanvas.height = canvas.height;
        this.allCanvas.push(offscreenCanvas);


            // Function to resize the canvas
        const resizeCanvas = () => {

            // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas
            const dpr = window.devicePixelRatio;
            // Get the device pixel ratio, falling back to 1.
            this.pixelRatio = window.devicePixelRatio || 1;
            if(!hidpi)
            {
                this.pixelRatio = 1;
            }

            var container = this.el;

            const rect = container.getBoundingClientRect();
            for(var i = 0; i < this.allCanvas.length; i++)
            {
                var canvas = this.allCanvas[i];
                // canvas.width = container.clientWidth;
                // canvas.height = container.clientHeight;

                // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas
                // const rect = canvas.getBoundingClientRect();

                // Set the "actual" size of the canvas
                canvas.width = rect.width * this.pixelRatio;
                canvas.height = rect.height * this.pixelRatio;

                // Scale the context to ensure correct drawing operations
                // ctx.scale(dpr, dpr);

                // Set the "drawn" size of the canvas
                canvas.style.width = `${rect.width}px`;
                canvas.style.height = `${rect.height}px`;
            }
  
            this.drawVisible();
        };
  
      // Initial resize
      resizeCanvas();
  
      // Listen for resize events
    window.addEventListener('resize', resizeCanvas);



    // panning and zooming

    // on mousedown, start dragging
    container.addEventListener('contextmenu', function(event) {
        event.preventDefault(); // Prevent the default context menu
      
        // Perform custom actions or show a custom context menu
      });
    container.addEventListener('mousedown', e => {
        //only accept the left mouse button
        e.preventDefault();
        if(e.button != 2)
            return;

        this.isDragging = true;
        this.previousMousePosition = this.getMousePos(canvas, e);
    });

    // on mousemove, if is dragging, calculate new pan offset and redraw
    container.addEventListener('mousemove', e => {
        if (this.isDragging) {
            const mousePosition = this.getMousePos(canvas, e);
            this.pan.x += mousePosition.x - this.previousMousePosition.x;
            this.pan.y += mousePosition.y - this.previousMousePosition.y;
            // console.log("pan", this.pan, mousePosition);
            this.previousMousePosition = mousePosition;
            this.drawVisible();
            e.preventDefault();
            return;
        }

        //get the color of the pixel under the mouse
        // var rect = this.allCanvas[0].getBoundingClientRect();
        // var ex = e.x - rect.left;
        // var ey = e.y - rect.top;

        const height = canvas.height;
        const ne = this.getMousePos(canvas, e);
        var ex = ne.x;
        var ey = ne.y;

        // console.log("ex", ex, "ey", ey, "e", e.clientY, "height", height - e.clientY);

        var ctx = this.allCanvas[1].getContext("2d");
        // console.log("ctx", ctx.getContextAttributes().colorSpace);
        var imgData = ctx.getImageData(ex, height - ey, 1, 1);
        var data = imgData.data;
        var valid = true;
        for(var dy = -1; dy <= 1; ++dy)
        {
            for(var dx = -1; dx <= 1; ++dx)
            {
                var i = ctx.getImageData(ex+dx, height - ey+dy, 1, 1);
                if(i.data[0] != data[0] || i.data[1] != data[1] || i.data[2] != data[2])
                {
                    valid = false;
                    break;
                }
            }
        }

        let c = rgbToHex(data[0], data[1], data[2]);
        //get id from the color map
        var id = this.colors[c];
        if(id == undefined)
        {
            id = -1;
        }

        if(this.hoveredElement != id)
        {
            this.hoveredElement = id;
            // this.draw(true);
            requestAnimationFrame(()=>{this.draw(true)});
        }
        
        // console.log("color", c, id, " at ", ex, ey, valid, data[0], data[1], data[2]);
        // console.log("color", c, id, valid, data[0], data[1], data[2]);
    });

        // // on mouseup or mouseout, stop dragging
    container.addEventListener('mouseup', () => this.isDragging = false);
    container.addEventListener('mouseout', () => this.isDragging = false);

    //Zoom
    container.addEventListener('wheel', (event) => {
        let zoomFactor = 1.1;
        // prevent the default scroll behavior
        event.preventDefault();
      
        // Get the current mouse position
        let mouseViewPos = this.getMousePos(canvas, event);
        // console.log("mouseViewPos", mouseViewPos);
        let mouseWorldPosBeforeZoom = this.canvasToWorld(mouseViewPos);
      
        // console.log("mouseWorldPosBeforeZoom", event.deltaY, mouseWorldPosBeforeZoom);
        // Update the zoom level
        if (event.deltaY < 0) {
            this.zoom *= zoomFactor;
        } else {
            this.zoom /= zoomFactor;
        }
      
        // Calculate the new mouse position and adjust the pan so the position under the cursor stays the same
        let mouseWorldPosAfterZoom = this.canvasToWorld(mouseViewPos);
        this.pan.x -= (mouseWorldPosBeforeZoom.x - mouseWorldPosAfterZoom.x) * this.zoom;
        this.pan.y -= (mouseWorldPosBeforeZoom.y - mouseWorldPosAfterZoom.y) * this.zoom;

        
        // const mousePosition = this.getMousePos(canvas, e);
        // this.pan.x += mousePosition.x - this.previousMousePosition.x;
        // this.pan.y += mousePosition.y - this.previousMousePosition.y;
      
        // Redraw the scene
        this.drawVisible();

      });


      // Touch events
    let initialDistance = 0;
    let initialMidpoint = {x: 0, y: 0};
    let touchStartPos = {x: 0, y: 0};

    container.addEventListener('touchstart', (event) => {
        event.preventDefault();
        if (event.touches.length === 2) { // Two fingers touching
            initialDistance = getDistance(event.touches[0], event.touches[1]);
            initialMidpoint = getMidpoint(event.touches[0], event.touches[1]);

            let p1 = this.getMousePos(canvas, event.touches[0]);
            let p2 = this.getMousePos(canvas, event.touches[1]);
            // let mousePosition = getMidpoint(p1, p2);
            touchStartPos = {x: (p1.x + p2.x)/2, y: (p1.y + p2.y)/2};
            touchStartPos = this.canvasToWorld(touchStartPos);
            let mousePosition = {x: (p1.x + p2.x)/2, y: (p1.y + p2.y)/2};
            this.previousMousePosition = mousePosition;
        }

    }, false);

    container.addEventListener('touchend', (event) => {

    }, false);

    container.addEventListener('touchmove', (event) => {
        // console.log("touchmove", event.touches.length);
        event.preventDefault();
        if (event.touches.length === 2) { // Two fingers moving
            let distance = getDistance(event.touches[0], event.touches[1]);
            let midpoint = getMidpoint(event.touches[0], event.touches[1]);

            let scaleFactor = distance / initialDistance;
            
            // ctx.translate(initialMidpoint.x, initialMidpoint.y);
            // ctx.scale(scaleFactor, scaleFactor);
            this.zoom *= scaleFactor;
            // this.pan.x -= (initialMidpoint.x - midpoint.x) * this.zoom;
            // this.pan.y += (initialMidpoint.y - midpoint.y) * this.zoom;
            // ctx.translate(-initialMidpoint.x, -initialMidpoint.y);

            let p1 = this.getMousePos(canvas, event.touches[0]);
            let p2 = this.getMousePos(canvas, event.touches[1]);
            let mousePosition = {x: (p1.x + p2.x)/2, y: (p1.y + p2.y)/2};
            let world = this.canvasToWorld(mousePosition);

            const dx = world.x - touchStartPos.x;
            const dy = world.y - touchStartPos.y;
            this.pan.x += dx * this.zoom;
            this.pan.y += dy * this.zoom;

            this.previousMousePosition = mousePosition;

            initialDistance = distance;
            initialMidpoint = midpoint;
            
            this.drawVisible();
        }

    }, false);

    // Calculate the distance between two touch points
    function getDistance(touch1, touch2) {
        let dx = touch1.clientX - touch2.clientX;
        let dy = touch1.clientY - touch2.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    // Calculate the midpoint between two touch points
    function getMidpoint(touch1, touch2) {
        return {
            x: (touch1.clientX + touch2.clientX) / 2,
            y: (touch1.clientY + touch2.clientY) / 2,
        };
    }



    }

    setElements(el)
    {
        console.log("setElements", el.length);
        this.elements = el;

        // this.colors = [];
        //create a map
        this.colors = new Map();
        for(var i = 0; i < this.elements.length; ++i)
        {
            var c = this.generateColorFromId(i);
            this.elements[i].pickColor = c;
            this.colors[c] = i;
        }

        // this.draw();
        this.drawVisible();
        // requestAnimationFrame(()=>{this.draw()});
    }



    // utility function to get mouse position

    getCanvasFromEvent(e)
    {
        // var rect = this.allCanvas[0].getBoundingClientRect();
        // var x = (e.x - rect.left) * this.pixelRatio;
        // var y = (e.y - rect.top) * this.pixelRatio;
        // return {x: x, y: y};

        return this.getMousePos(this.allCanvas[0], e);
    }

    getMousePos(canvas, evt)
    {
        var px = 0;
        var py = 0;
        //if it's a mouse event
        if(evt instanceof MouseEvent)
        {
            var px = evt.clientX;
            var py = evt.clientY;
        }
        else
        {
            // px = evt.
        }

        const rect = canvas.getBoundingClientRect();

        var x = (evt.clientX - rect.left) * this.pixelRatio;
        var y = (rect.height - (evt.clientY - rect.top)) * this.pixelRatio;

        return {
            x: x,
            y: y
        };
    }

    viewToWorld(e)
    {
        var c = this.getCanvasFromEvent(e);
        // var rect = this.allCanvas[0].getBoundingClientRect();
        let worldX = (c.x - this.pan.x) / this.zoom;
        let worldY = (c.y - this.pan.y) / this.zoom;  // subtract from canvas height because of y-flip
        return {x: worldX, y: worldY};
    }

    canvasToWorld(c)
    {
        let worldX = (c.x - this.pan.x) / this.zoom;
        let worldY = (c.y - this.pan.y) / this.zoom;  // subtract from canvas height because of y-flip
        return {x: worldX, y: worldY};
    }

    worldToView(e)
    {
        var c = this.getCanvasFromEvent(e);
        let viewX = (c.x * this.zoom) + this.pan.x;
        let viewY = (c.y * this.zoom) + this.pan.y;  // subtract from canvas height because of y-flip
        return {x: viewX, y: viewY};
    }

    drawVisible()
    {
        // Redraw the scene
        //this.draw(true);
        requestAnimationFrame(()=>{this.draw(true)});

        if(this.redrawTimeout)
        {
            clearTimeout(this.redrawTimeout);
        }

        this.redrawTimeout = setTimeout(() =>{
            this.draw();
            console.log("redraw pick");
            this.redrawTimeout = null;
        }, redrawTime);
    }

    draw(visible)
    {
        if(visible == undefined)
        {
            visible = false;
        }

        if(this.allCanvas.length == 0)
        {
            return;
        }

        for(var i = 0; i < this.allCanvas.length; i++)
        {
            if(visible && i != 0)
            {
                continue;
            }

            var ctx = this.allCanvas[i].getContext("2d");
            var rect = this.allCanvas[i].getBoundingClientRect();
            var width = this.allCanvas[i].width;
            var height = this.allCanvas[i].height;

            //TODO - flag to align to pixel grid or not plus no alpha
            if(i == 0)
            {
                measureExecutionTime(()=>{
                    this.drawCtx(ctx, width, height, i==0?1:20, i==0?true:false);
                }, i == 0 ? "Visible" : "Pick");
            }
            else
            {
                measureExecutionTime(()=>{
                    this.drawCtx(ctx, width, height, 20, false);
                    // this.drawCtx(ctx, width, height, 5, false, false);
                    // this.drawCtx(ctx, width, height, i==0?1:5, i==0?true:false, false);
                }, i == 0 ? "Visible" : "Pick");
            }
        }

    }

    /**
     * @param {number} id
     */
    generateColorFromId(id)
    {
        // A large prime number
        let prime = 10007;
        // Hash the number
        let hash = id * prime;
        // Generate the RGB color components from the hash
        let r = (hash >> 0) & 0xFF;
        let g = (hash >> 8) & 0xFF;
        let b = (hash >> 16) & 0xFF;

        //convert to hex
        r = r.toString(16);
        g = g.toString(16);
        b = b.toString(16);

        //pad with 0s
        if(r.length == 1)
        {
            r = "0" + r;
        }
        if(g.length == 1)
        {
            g = "0" + g;
        }
        if(b.length == 1)
        {
            b = "0" + b;
        }

        return "#" + r + g + b;
    }

    drawPath(ctx, el)
    {
        ctx.beginPath();
            var lastPoint = {x: 0, y: 0};
            for(var j = 0; j < el.length; j++)
            {
                switch(el[j].t)
                {
                    case "M":
                        ctx.moveTo(el[j].p[0], el[j].p[1]);
                        lastPoint = {x: el[j].p[0], y: el[j].p[1]};
                        break;
                    case "m":
                        ctx.moveTo(lastPoint.x + el[j].p[0], lastPoint.y + el[j].p[1]);
                        lastPoint = {x: lastPoint.x + el[j].p[0], y: lastPoint.y + el[j].p[1]};
                        break;
                    case "l":
                        ctx.lineTo(el[j].p[0], el[j].p[1]);
                        break;
                    case "q":
                        ctx.quadraticCurveTo(el[j].p[0], el[j].p[1], el[j].p[2], el[j].p[3]);
                        break;
                    case "C":
                        ctx.bezierCurveTo(el[j].p[0], el[j].p[1], el[j].p[2], el[j].p[3], el[j].p[4], el[j].p[5]);
                        break;
                    case "a":
                        ctx.ellipse(el[j].p[0], el[j].p[1], el[j].p[2], el[j].p[3], el[j].p[4], el[j].p[5], el[j].p[6]);
                        break;
                    case "z":
                        ctx.closePath();
                        break;
                    
                }
            }

            ctx.stroke();
    }

    drawCtx(ctx, width, height, stroke, visible, doClear)
    {
        if(doClear === undefined  || doClear === null)
        {
            doClear = true;
        }

        // visible = false;
        stroke *= this.pixelRatio;
        // stroke = 10;
        if(!visible)
        {
            //turn off blending
            ctx.globalCompositeOperation = "source-over";
            //turn off antialiasing
            ctx.imageSmoothingEnabled = false;
            ctx.globalAlpha = 1;
        }
        else
        {
            //turn on blending
            ctx.globalCompositeOperation = "source-over";
            //turn on antialiasing
            ctx.imageSmoothingEnabled = true;
            ctx.globalAlpha = 1;
        }

        //reset the canvas transform
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        
        if(doClear)
        {
            ctx.clearRect(0, 0, width, height);
        }

        ctx.strokeOpacity = 1;
        ctx.fillOpacity = 1;

        if(!visible && doClear)
        {
            ctx.fillStyle = "#FFFFFF";
            ctx.fillRect(0, 0, width, height);
        }


        ctx.save();
        // Scale the y coordinates by -1 (flips the canvas vertically)
        ctx.scale(1, -1);
        // Translate the y coordinates by -canvas.height (moves the origin to the bottom)
        ctx.translate(0, -height);
        ctx.translate(this.pan.x, this.pan.y);
        ctx.scale(this.zoom, this.zoom);

        //set stroke width
        var strokeWidth = stroke / this.zoom;
        // make sure it's a whole number
        // strokeWidth = Math.round(strokeWidth);
        // console.log("strokeWidth", strokeWidth);

        ctx.lineWidth = strokeWidth;
        ctx.lineJoin = 'bevel';

        ctx.strokeStyle = "#000000";
        for(var i = 0; i < this.elements.length; i++)
        {
            const el = this.elements[i];
            // console.log("el", el);

            //generate a random color with a fixed alpha based on the seed value of i
            var color = this.generateColorFromId(i);
            if(visible)
            {
                ctx.strokeStyle = "black";
                // ctx.strokeStyle = el.pickColor;
                ctx.lineWidth = strokeWidth;
            }
            else
            {
                ctx.strokeStyle = el.pickColor;
                ctx.lineWidth = strokeWidth;
            }
            
            
            this.drawPath(ctx, el);
        }

        //draw hovered element on top
        if(visible)
        {
            if(this.hoveredElement >= 0)
            {
                const el = this.elements[this.hoveredElement];

                // ctx.save();
                // ctx.shadowColor = "black";
                // ctx.shadowBlur = 5;
                // ctx.strokeStyle = "#aaa";
                // ctx.lineWidth = strokeWidth * 8;
                // ctx.strokeOpacity = 0.5;
                // ctx.globalAlpha = 0.5;
                // this.drawPath(ctx, el); 
                // ctx.restore();

                ctx.save();

                ctx.shadowColor = "#555";
                ctx.shadowBlur = 7;

                ctx.strokeStyle = "red";
                ctx.lineWidth = strokeWidth * 2;
                this.drawPath(ctx, el); 
                ctx.restore();

            }
            else
            {
                ctx.strokeStyle = "black";
                // ctx.strokeStyle = el.pickColor;
                ctx.lineWidth = strokeWidth;
            }
        }

        ctx.restore();
    }
}

function getEngine()
{
    return engine;
}

function createEngine(element)
{
    if(engine == null && element == null)
    {
        throw "createEngine: element is null";
    }

    if(engine == null)
    {
        engine = new McEngine(element);
    }

    return engine;
}

module.exports = {
    createEngine,
    getEngine
}
