
// import "./index.css";

var ui = {
    position: null,
    rulers: null,
    pickDistance: 10,
    adjustedPickDistance: 10,
    documentLayer: null,
    backgroundLayer: null,
}


// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
// import Greeting from './components/Greeting';
// import IncButton from './components/IncButton';
// import LayerList from './components/LayerList';
import LeftPane from './components/LeftPane';
// import Paper from './components/Paper';
import Engine from './components/Engine';
import { counter } from './reducers';

// Create the store with the counter reducer
const store = createStore(counter);
window.store = store;
/*

<div>
    <LeftPanel myClass="my-custom-class" />
    <RightPanel />
    <Paper/>
</div>

*/

const App = () => (
  <div className=''>
    <LeftPane/>
    {/* <Greeting name="React counter" />
    <IncButton />
    <LayerList />  */}
    {/* <Paper/> */}
    <Engine/>
  </div>
);

// Here's the new way to render your root component
const root = document.getElementById('root');
createRoot(root).render(
  <Provider store={store}>
    <App />
  </Provider>
);
