// src/components/LayerList.js
import React from 'react';
import { useSelector } from 'react-redux';
import AddLayerButton from './AddLayerButton';

const LayerList = () => {
  // Select the 'layers' field from the state
  const layers = useSelector(state => state.layers);
  return (
    <div>
    <ul className='mt-3'>
      {layers.map((layer, index) => <li key={index}>{layer}</li>)}
    </ul>

    <AddLayerButton/>
    </div>
  );
};

export default LayerList;
