import stateCreator from './state.js';

// src/reducers.js
const INCREMENT = 'INCREMENT';
export const ADD_LAYER = 'ADD_LAYER'; // define the action type
export const SET_FPS = 'SET_FPS'; // define the action type
export const ADD_ELEMENTS = 'ADD_ELEMENTS'; // define the action type

// This is the action creator
export function increment() {
  return { type: INCREMENT };
}

export function addLayer(layer) { // define the action creator
  return { type: ADD_LAYER, payload: layer };
}

export function setFps(f) { // define the action creator
  return { type: SET_FPS, payload: f };
}

export function addElements(f) { // define the action creator
  return { type: ADD_ELEMENTS, payload: f };
}


var s = stateCreator();

// This is the reducer, updated to use an object for the state
export function counter(state = s, action) {
  switch (action.type) {
    case INCREMENT:
      return { ...state, count: state.count + 1 };
      case ADD_LAYER: // handle the action in your reducer
        return { ...state, layers: [...state.layers, action.payload] };
      case SET_FPS: // handle the action in your reducer
        return { ...state, fps: action.payload };
      case ADD_ELEMENTS: // handle the action in your reducer
        //append the payload to the elements array
        var ret = { ...state, elements: [] };

        for(var i = 0; i < action.payload.length; i++)
        {
          ret.elements.push(action.payload[i]);
        }

        return ret;
        
        return { ...state, elements: [...state.elements, action.payload] };
    default:
      return state;
  }
}
