

function createPath(child)
{
    const newPath = new Path({
        segments: child.segments,
        closed: child.closed,
        fillColor: "", //child.fillColor,
        strokeColor: "black", //child.strokeColor,
        strokeWidth: 1, //child.strokeWidth,
        opacity: 1
    });


    // Copy any other attributes from the compound path to the new path
    newPath.copyAttributes(child, true);

    newPath.strokeWidth = 2;
    newPath.strokeColor = 'black';
    newPath.fillColor = null;
    // newPath.applyMatrix = false;

    return newPath;
}

function breakCompoundPaths(item, level) {

    if(level == undefined)
    {
        level = 0;
    }

    if(item instanceof paper.Path)
    {
        console.log("!!!!!!!!!!!!!!!!!!Child is path");
        // createPath(child);
        createPath(child).addTo(ui.documentLayer);
        // return;
    }

    if(item instanceof paper.Shape)
    {
        console.log("!!!!!!!!!!!!!!!!!!Child is compound path");
        // createPath(child);
        createPath(item.toPath(false)).addTo(ui.documentLayer);
        // return;
    }

    if(item.hasChildren()){
            
        item.children.forEach((child) => {
            if(child instanceof Curve)
            {
                console.log(level, "Child is curve");
            }
            if(child instanceof Segment)
            {
                console.log(level, "Child is segment");
            }

            if(child instanceof paper.Path)
            {
                // console.log(level, "Child is path", child.segments.length);
                createPath(child).addTo(ui.documentLayer);

                // child.clone( { insert: false, deep: true }).addTo(ui.documentLayer);
                // child.clone( { insert: true, deep: false });
                if(child.hasChildren())
                {
                    console.log(level, "Child has children");
                }
                // child.remove();
            }
            else 
            {
                // if(child instanceof Group) console.log(level, "Child is group");
                // else if(child instanceof CompoundPath) console.log(level, "Child is compound path");
                // else console.log(level, "Child is not path");

                breakCompoundPaths(child, level+1);
                // child.remove();
            }
        });

        item.removeChildren();
    }
}

export function loadSVG(svg)
{
    paper.project.importSVG(svg, {
        expandShapes: true,
        onLoad: function(item) {
            // Center the imported SVG on the canvas
            ui.documentLayer.removeChildren();
            item.position = paper.view.center;
            ui.documentLayer.addChild(item);
        
            // Optionally, resize the item to fit the canvas
            item.fitBounds(paper.view.bounds);

            breakCompoundPaths(item);
            item.remove();

            ui.documentLayer.children.forEach(function(path) {
                path.strokeWidth = 2;
                path.strokeColor = 'black';
                path.fillColor = null;
            });


            // setTimeout(function() {
            //     onFrame(true);
            // }, 100);
        },
        onError: function(message) {
            // Something went wrong!
            console.error(message);
        }
    
    });
}