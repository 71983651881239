// src/Button.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { increment } from '../reducers';

const Button = () => {
  const dispatch = useDispatch();
  return <button onClick={() => dispatch(increment())} className="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded">Increment</button>;
};

export default Button;
