// src/components/LayerList.js
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Greeting from './Greeting';
import IncButton from './IncButton';
import LayerList from './LayerList';

import { loadSVG } from '../js/LoadSvg';

const LeftPane = () => {
  // Select the 'layers' field from the state
//   const layers = useSelector(state => state.layers);
  const fps = useSelector(state => state.fps);

  useEffect(() => {
    // Code to run after component is added to the DOM

// // Get the drop target
    var dropTarget = document.getElementById('droptarget');

    // Handle the dragover event
    dropTarget.addEventListener('dragover', function(event) {
        // Prevent default behavior (which would, for example, allow the file to be opened)
        event.preventDefault();
    });

    // Handle the drop event
    dropTarget.addEventListener('drop', function(event) {
        // Prevent default behavior (which would, for example, allow the file to be opened)
        event.preventDefault();

        // Use DataTransfer interface to access the file(s)
        for (var i = 0; i < event.dataTransfer.items.length; i++) {
            // If dropped items aren't files, reject them
            if (event.dataTransfer.items[i].kind === 'file') {
                var file = event.dataTransfer.items[i].getAsFile();
                var reader = new FileReader();
                
                reader.onload = function(event) {
                    loadSVG(event.target.result);
                };
                
                reader.readAsText(file);
            }  
        }
    });

  }, []);

  return (
    <div className='leftPane' >
        <div id="position"></div>
        <div id="fps">{fps}</div>
        <div id="droptarget">
            <span>Drop an SVG here</span>
        </div>
        <div>
            <Greeting name="React counter" />
            <IncButton />
            <LayerList /> 
        </div>
    
    </div>
  );
};

export default LeftPane;
